﻿@import "Variables.scss";

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

* {
    margin: 0;
    padding: 0;
    letter-spacing: 0;
}

body {
    font-family: $font-family-Arial;
}

.align-left {
    text-align: left;
}

.hide {
    display: none !important;
}

header {
    border-bottom: 0.125rem solid #e3e4e6;

    .topHeader {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1.188rem 1.25rem;
    }

    .helpline_text {
        float: right;
        margin: auto 0;

        a {
            color: #001D6C;
            font-family: $font-family-Arial;
            font-weight: bold;
            font-size: 0.875rem;
            line-height: 1.563rem;
            letter-spacing: 0;
            margin-left: 0;
            text-decoration: none;
        }
    }

    .headerLogo {
        &:focus {
            outline: none;
        }

        &:focus-visible {
            outline: none;
        }

        img {
            &:focus-visible {
                outline: none;
            }
        }
    }

    .logoCCI {
        img {
            width: 14.188rem;
            height: 2.938rem;
        }
    }

    .logoFidium {
        img {
            width: 7.938rem;
            height: 3.375rem;
        }
    }
}

.tele-call-number {
    &:focus-visible {
        outline: none;
    }
}

.onetimePaymentModule {
    position: relative;

    h1.module-title {
        font-family: $font-family-Arial;
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 2.5rem;
        letter-spacing: -0.09rem;
        margin-bottom: 3.563rem;
        text-align: center;
        color: #001D6C;
    }
}

.bg-gray {
    background: #F4F4F4;
}

.otp-account-info {
    margin: 0 auto;
    max-width: 100%;
    padding: 6.563rem 1.25rem 5rem;
    width: 36.75rem;
    text-align: center;
    position: relative;
}

.module-heading {
    margin-bottom: 2rem;

    h2 {
        color: $black-color;
        font-family: $font-family-Arial;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.563rem;
        letter-spacing: -0.054rem;
        margin-bottom: 0.625rem;
    }

    .module-description {
        color: #000;
        font-family: $font-family-Arial;
        font-size: 0.875rem;
        line-height: 1.125rem;
        letter-spacing: 0;
    }
}

.account-info-form {
    .fieldgroup {
        margin-bottom: 1.56rem;
        position: relative;
    }

    fieldset {
        position: relative;
        padding-bottom: 1.68rem;
    }

    label {
        color: $black-color;
        font-family: $font-family-Arial;
        font-weight: bold;
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: 1.563rem;
        margin-bottom: 0;
    }

    input {
        background: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 0.125rem solid #808185;
        box-shadow: none;
        color: $black-color;
        font-family: $font-family-Arial;
        font-size: 0.875rem;
        line-height: 1.563rem;
        padding-bottom: 0.188rem;

        &:focus {
            border-color: #808185;
            outline: none;
            box-shadow: unset;
            background: transparent;
        }

        &:focus-visible {
            outline: none;
            box-shadow: unset;
            background: transparent;
        }
    }

    .toPopUp {
        &:focus-visible {
            outline: none;
            box-shadow: unset;
            background: transparent;
        }

        color: $blue-color;
        cursor: pointer;
        font-family: $font-family-Arial;
        font-size: 0.75rem;
        letter-spacing: 0;
        line-height: 0.875rem;
        outline: none;
        padding: 0;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            text-decoration: underline;
        }

        &:active {
            color: $blue-color;
            border: 0.063rem solid transparent;
        }
    }

    .btnToLink {
        color: $blue-color;
        cursor: pointer;
        font-family: $font-family-Arial;
        font-size: 0.75rem;
        letter-spacing: 0;
        line-height: 0.875rem;
        outline: none;
        padding: 0;

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            text-decoration: underline;
        }

        &:active {
            color: $blue-color;
            border: 0.063rem solid transparent;
        }
    }

    .onErrorMessage {
        position: absolute;
        left: 0;
        bottom: -1.18rem;
        cursor: inherit;
        color: #B3060C;
        text-align: center;
        text-decoration: none;
        font-size: 0.75rem;
    }

    .onErrorMessage.center {
        right: 0;
        bottom: 0.4rem;
    }

    .customButton {
        text-align: center;
        margin-top: 0;

        .action-btn {
            background-color: $background-color-blue;
            border: 0.125rem solid #001D6C;
            border-radius: 0.25rem;
            box-shadow: none;
            color: $white-color;
            font-family: $font-family-Arial;
            font-weight: bold;
            font-size: 1.25rem;
            letter-spacing: -0.045rem;
            line-height: 1.563rem;
            margin-right: 1.25rem;
            padding: 0.625rem 1.75rem;
            outline: none;
            outline-offset: 0;
            margin-right: 0 !important;

            &:hover {
                border: 0.125rem solid #00AE42;
                border: 0.125rem solid #00AE42;
                background-color: $background-color-blue;
            }

            &:focus {
                border: 0.125rem solid #00AE42;
                border: 0.125rem solid #00AE42;
                background-color: $background-color-blue;
            }

            &:active {
                border: 0.125rem solid #00AE42;
                border: 0.125rem solid #00AE42;
                background-color: $background-color-blue;
            }
        }
    }
}

.otp-popup {
    .popupBtn {
        button {
            background-color: $background-color-blue;
            border: 0.125rem solid #001D6C;
            border-radius: 0.25rem;
            box-shadow: none;
            color: $white-color;
            font-family: $font-family-Arial;
            font-weight: bold;
            font-size: 1.25rem;
            letter-spacing: -0.045rem;
            line-height: 1.563rem;
            margin-right: 1.25rem;
            padding: 0.625rem 1.75rem;
            outline: none;
            outline-offset: 0;
            margin-right: 0 !important;

            &:hover {
                border: 0.125rem solid #00AE42;
                background-color: $background-color-blue;
            }

            &:focus {
                border: 0.125rem solid #00AE42;
                background-color: $background-color-blue;
            }

            &:active {
                border: 0.125rem solid #00AE42;
                background-color: $background-color-blue;
            }

            &:first-child {
                &:active {
                    border: 0.125rem solid #00AE42;
                    background-color: $background-color-blue;
                }
            }
        }
    }

    .modal-content {
        padding: 3.438rem 1.563rem 2.188rem;
    }

    .modal-title {
        color: $black-color;
        font-family: $font-family-Arial;
        font-weight: bold;
        font-size: 2rem;
        letter-spacing: -0.072rem;
        line-height: 2.25rem;
    }

    .btn-close {
        color: $blue-color;
        font-weight: normal;
        background: transparent;
        padding: 0;
        margin: 0;
        font-size: 2.25rem;
        opacity: 1;
        right: -1.875rem;
        top: -3.75rem;
        position: relative;
    }

    .modal-header {
        border-radius: 0;
        border: 0;
        padding-bottom: 0.313rem;
        padding-top: 0.313rem;
    }

    .modal-body {
        img {
            max-width: 100%;
        }

        p {
            margin: 0;
            padding-bottom: 0.188rem;
            color: #3A3A3A;
            letter-spacing: 0;
            line-height: 1.563rem;
            font-family: $font-family-Arial;
            font-size: 0.875rem;
            margin-bottom: 1.438rem;
        }

        .bold-content {
            font-family: $font-family-Arial;
            font-weight: bold;
            margin-bottom: 0.375rem;
            padding-bottom: 0.938rem;
            margin-bottom: 0.313rem !important;
        }

        figcaption {
            p {
                padding-bottom: 0.25rem !important;
                margin-bottom: 0;
            }
        }
    }

    .tele-call-number {
        color: $blue-color;
        font-family: $font-family-Arial;
        font-weight: bold;
        font-size: 1.5rem;
        letter-spacing: -0.054rem;
        line-height: 1.563rem;
        margin-bottom: 1.875rem;
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
    }

    figure {
        margin: 0 0 1.188rem;
    }
}

button {
    &:focus {
        outline: none;
        box-shadow: none !important;
    }
}

.otp-popup.modal-dialog {
    max-width: 40.5rem;
    box-shadow: 0 0.313rem 0.938rem rgba(0,0,0,.5);
    border-radius: 0.5rem;
}

.modal-backdrop {
    background-color: $background-color-blue !important;
    opacity: .9 !important;
}

#locatePinPopUp {
    .otp-popup {
        .modal-body {
            padding-top: 1.25rem;
        }

        figcaption {
            padding-bottom: 1.25rem;
        }
    }
}

footer {
    border-top: 0.063rem solid #ddd;
    color: #505257;
    font-size: 0.875rem !important;
    font-family: $font-family-Arial;
    line-height: 0.875rem;
    margin: 0;
    padding: 3rem 0 1.875rem;
    text-align: center;

    .footer-content {
        text-align: center;

        ul {
            list-style: none;
            margin-left: 0;
            padding-bottom: 0.313rem;
            margin-bottom: 0.625rem;

            li {
                display: inline-block;
                font-size: 0.875rem;
                font-family: $font-family-Arial;
                line-height: 0.875rem;
                padding-right: 1rem;

                &:last-child {
                    padding-right: 0;
                }

                a {
                    cursor: pointer;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    text-decoration: none;
                    color: #2e2e2e;
                    font-family: $font-family-Arial;
                }
            }
        }
    }

    .copyright {
        font-size: 0.75rem;
        font-family: $font-family-Arial;
        color: $darkgray-color;
        line-height: 1.563rem;
        padding-bottom: 0.5rem;
    }

    .privacyAct {
        font-size: 0.75rem;
        font-family: $font-family-Arial;
        color: $darkgray-color;
        line-height: 1.563rem;

        a {
            color: $darkgray-color;
            text-decoration: underline;
            font-family: $font-family-Arial;
            font-weight: bold;
        }
    }
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.spinner {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
    font-size: 3em;
    color: $lightgreen-color;

    &:before {
        content: "";
    }
}

.fa-spinner {
    &:before {
        content: "";
    }

    color: $lightgreen-color;
}

.fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}

.fa-3x {
    font-size: 3em;
}

.loading-spinner {
    text-align: center;
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    z-index: 999;
}

.agentLogin-main-wrapper {
    position: relative;
}

@media screen and (max-width: 991px) {
    .onetimePaymentModule {
        width: 100%;

        h1.module-title {
            font-size: 2rem;
            margin-bottom: 1.875rem !important;
        }

        .customButton {
            margin-top: 0;
            display: flex;
            justify-content: center;

            .action-btn {
                display: block;
                margin-bottom: 0.625rem;
            }
        }
    }

    .module-heading {
        font-size: 1.25rem;
        margin-bottom: 1.25rem;
    }

    .otp-popup {
        .modal-content {
            padding: 1.875rem;
        }

        .modal-header {
            h1 {
                font-size: 1.25rem;
                line-height: 1.25rem;
            }

            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0.75rem;
        }

        button.close {
            top: 1.25rem;
        }

        .modal-body {
            padding: 0;

            img {
                max-width: 100%;
            }
        }

        .tele-call-number {
            font-size: 1.25rem;
        }

        .btn {
            font-size: 1.125rem;
        }

        .btn-close {
            right: -1.563rem;
            top: -2.188rem;
        }
    }

    header {
        .topHeader {
            padding: 0.813rem 0 0.5rem;
        }
    }

    footer {
        padding: 1.25rem 0;

        .footer-content {
            display: block;

            ul {
                margin-bottom: 0;
                text-align: center;
                padding-left: 0;
                list-style: none;
            }
        }

        .copyright {
            padding-bottom: 0 !important;
        }

        .privacyAct {
            padding-bottom: 0 !important;
        }
    }

    .customFooter_OTP {
        .footer-content {
            ul {
                li {
                    text-align: left;
                    padding: 0 0.938rem 0.625rem 0;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .otp-account-info {
        padding: 1.25rem;
        width: 100%;
    }

    .otp-popup {
        .modal-header {
            padding: 0;
            margin-bottom: 0.75rem;
        }

        .btn-close {
            right: -0.625rem;
            top: -2.188rem;
        }

        .popupBtn {
            margin-top: 1.563rem;
        }
    }

    .headerLogo.logoCCI {
        img {
            width: 9rem;
            height: 2.5rem;
        }
    }

    .headerLogo.logoFidium {
        img {
            width: 6.25rem;
            height: 2.5rem;
        }
    }

    .otp-popup.modal-dialog {
        margin-top: 1.875rem;
        max-width: 31.25rem !important;
    }

    .onetimePaymentModule {
        .customButton {
            display: block;

            .action-btn {
                width: 100%;
            }
        }
    }
}

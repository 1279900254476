@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

* {
  letter-spacing: 0;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial;
}

.align-left {
  text-align: left;
}

.hide {
  display: none !important;
}

header {
  border-bottom: .125rem solid #e3e4e6;
}

header .topHeader {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.188rem 1.25rem;
  display: flex;
}

header .helpline_text {
  float: right;
  margin: auto 0;
}

header .helpline_text a {
  color: #001d6c;
  letter-spacing: 0;
  margin-left: 0;
  font-family: Arial;
  font-size: .875rem;
  font-weight: bold;
  line-height: 1.563rem;
  text-decoration: none;
}

header .headerLogo:focus, header .headerLogo:focus-visible, header .headerLogo img:focus-visible {
  outline: none;
}

header .logoCCI img {
  width: 14.188rem;
  height: 2.938rem;
}

header .logoFidium img {
  width: 7.938rem;
  height: 3.375rem;
}

.tele-call-number:focus-visible {
  outline: none;
}

.onetimePaymentModule {
  position: relative;
}

.onetimePaymentModule h1.module-title {
  letter-spacing: -.09rem;
  text-align: center;
  color: #001d6c;
  margin-bottom: 3.563rem;
  font-family: Arial;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 2.5rem;
}

.bg-gray {
  background: #f4f4f4;
}

.otp-account-info {
  text-align: center;
  width: 36.75rem;
  max-width: 100%;
  margin: 0 auto;
  padding: 6.563rem 1.25rem 5rem;
  position: relative;
}

.module-heading {
  margin-bottom: 2rem;
}

.module-heading h2 {
  color: #000;
  letter-spacing: -.054rem;
  margin-bottom: .625rem;
  font-family: Arial;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.563rem;
}

.module-heading .module-description {
  color: #000;
  letter-spacing: 0;
  font-family: Arial;
  font-size: .875rem;
  line-height: 1.125rem;
}

.account-info-form .fieldgroup {
  margin-bottom: 1.56rem;
  position: relative;
}

.account-info-form fieldset {
  padding-bottom: 1.68rem;
  position: relative;
}

.account-info-form label {
  color: #000;
  letter-spacing: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: .875rem;
  font-weight: bold;
  line-height: 1.563rem;
}

.account-info-form input {
  box-shadow: none;
  color: #000;
  background: none;
  border: none;
  border-bottom: .125rem solid #808185;
  border-radius: 0;
  padding-bottom: .188rem;
  font-family: Arial;
  font-size: .875rem;
  line-height: 1.563rem;
}

.account-info-form input:focus {
  box-shadow: unset;
  background: none;
  border-color: #808185;
  outline: none;
}

.account-info-form input:focus-visible {
  box-shadow: unset;
  background: none;
  outline: none;
}

.account-info-form .toPopUp {
  color: #0651c1;
  cursor: pointer;
  letter-spacing: 0;
  outline: none;
  padding: 0;
  font-family: Arial;
  font-size: .75rem;
  line-height: .875rem;
  text-decoration: none;
}

.account-info-form .toPopUp:focus-visible {
  box-shadow: unset;
  background: none;
  outline: none;
}

.account-info-form .toPopUp:hover, .account-info-form .toPopUp:focus {
  text-decoration: underline;
}

.account-info-form .toPopUp:active {
  color: #0651c1;
  border: .063rem solid #0000;
}

.account-info-form .btnToLink {
  color: #0651c1;
  cursor: pointer;
  letter-spacing: 0;
  outline: none;
  padding: 0;
  font-family: Arial;
  font-size: .75rem;
  line-height: .875rem;
}

.account-info-form .btnToLink:hover, .account-info-form .btnToLink:focus {
  text-decoration: underline;
}

.account-info-form .btnToLink:active {
  color: #0651c1;
  border: .063rem solid #0000;
}

.account-info-form .onErrorMessage {
  cursor: inherit;
  color: #b3060c;
  text-align: center;
  font-size: .75rem;
  text-decoration: none;
  position: absolute;
  bottom: -1.18rem;
  left: 0;
}

.account-info-form .onErrorMessage.center {
  bottom: .4rem;
  right: 0;
}

.account-info-form .customButton {
  text-align: center;
  margin-top: 0;
}

.account-info-form .customButton .action-btn {
  box-shadow: none;
  color: #fff;
  letter-spacing: -.045rem;
  outline-offset: 0;
  background-color: #001d6c;
  border: .125rem solid #001d6c;
  border-radius: .25rem;
  outline: none;
  margin-right: 1.25rem;
  padding: .625rem 1.75rem;
  font-family: Arial;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.563rem;
  margin-right: 0 !important;
}

.account-info-form .customButton .action-btn:hover, .account-info-form .customButton .action-btn:focus, .account-info-form .customButton .action-btn:active {
  background-color: #001d6c;
  border: .125rem solid #00ae42;
}

.otp-popup .popupBtn button {
  box-shadow: none;
  color: #fff;
  letter-spacing: -.045rem;
  outline-offset: 0;
  background-color: #001d6c;
  border: .125rem solid #001d6c;
  border-radius: .25rem;
  outline: none;
  margin-right: 1.25rem;
  padding: .625rem 1.75rem;
  font-family: Arial;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.563rem;
  margin-right: 0 !important;
}

.otp-popup .popupBtn button:hover, .otp-popup .popupBtn button:focus, .otp-popup .popupBtn button:active, .otp-popup .popupBtn button:first-child:active {
  background-color: #001d6c;
  border: .125rem solid #00ae42;
}

.otp-popup .modal-content {
  padding: 3.438rem 1.563rem 2.188rem;
}

.otp-popup .modal-title {
  color: #000;
  letter-spacing: -.072rem;
  font-family: Arial;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.25rem;
}

.otp-popup .btn-close {
  color: #0651c1;
  opacity: 1;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 2.25rem;
  font-weight: normal;
  position: relative;
  top: -3.75rem;
  right: -1.875rem;
}

.otp-popup .modal-header {
  border: 0;
  border-radius: 0;
  padding-top: .313rem;
  padding-bottom: .313rem;
}

.otp-popup .modal-body img {
  max-width: 100%;
}

.otp-popup .modal-body p {
  color: #3a3a3a;
  letter-spacing: 0;
  margin: 0 0 1.438rem;
  padding-bottom: .188rem;
  font-family: Arial;
  font-size: .875rem;
  line-height: 1.563rem;
}

.otp-popup .modal-body .bold-content {
  margin-bottom: .375rem;
  padding-bottom: .938rem;
  font-family: Arial;
  font-weight: bold;
  margin-bottom: .313rem !important;
}

.otp-popup .modal-body figcaption p {
  margin-bottom: 0;
  padding-bottom: .25rem !important;
}

.otp-popup .tele-call-number {
  color: #0651c1;
  letter-spacing: -.054rem;
  cursor: pointer;
  margin-bottom: 1.875rem;
  font-family: Arial;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.563rem;
  text-decoration: none;
  display: inline-block;
}

.otp-popup figure {
  margin: 0 0 1.188rem;
}

button:focus {
  outline: none;
  box-shadow: none !important;
}

.otp-popup.modal-dialog {
  border-radius: .5rem;
  max-width: 40.5rem;
  box-shadow: 0 .313rem .938rem #00000080;
}

.modal-backdrop {
  opacity: .9 !important;
  background-color: #001d6c !important;
}

#locatePinPopUp .otp-popup .modal-body {
  padding-top: 1.25rem;
}

#locatePinPopUp .otp-popup figcaption {
  padding-bottom: 1.25rem;
}

footer {
  color: #505257;
  text-align: center;
  border-top: .063rem solid #ddd;
  margin: 0;
  padding: 3rem 0 1.875rem;
  font-family: Arial;
  line-height: .875rem;
  font-size: .875rem !important;
}

footer .footer-content {
  text-align: center;
}

footer .footer-content ul {
  margin-bottom: .625rem;
  margin-left: 0;
  padding-bottom: .313rem;
  list-style: none;
}

footer .footer-content ul li {
  padding-right: 1rem;
  font-family: Arial;
  font-size: .875rem;
  line-height: .875rem;
  display: inline-block;
}

footer .footer-content ul li:last-child {
  padding-right: 0;
}

footer .footer-content ul li a {
  cursor: pointer;
  color: #2e2e2e;
  font-family: Arial;
  font-size: .875rem;
  line-height: 1.25rem;
  text-decoration: none;
}

footer .copyright {
  color: #666;
  padding-bottom: .5rem;
  font-family: Arial;
  font-size: .75rem;
  line-height: 1.563rem;
}

footer .privacyAct {
  color: #666;
  font-family: Arial;
  font-size: .75rem;
  line-height: 1.563rem;
}

footer .privacyAct a {
  color: #666;
  font-family: Arial;
  font-weight: bold;
  text-decoration: underline;
}

.fa {
  font: 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.spinner {
  font: 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #90ee90;
  font-size: 3em;
  animation: 2s linear infinite fa-spin;
  display: inline-block;
}

.spinner:before {
  content: "";
}

.fa-spinner {
  color: #90ee90;
}

.fa-spinner:before {
  content: "";
}

.fa-spin {
  animation: 2s linear infinite fa-spin;
}

.fa-3x {
  font-size: 3em;
}

.loading-spinner {
  text-align: center;
  z-index: 999;
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
}

.agentLogin-main-wrapper {
  position: relative;
}

@media screen and (width <= 991px) {
  .onetimePaymentModule {
    width: 100%;
  }

  .onetimePaymentModule h1.module-title {
    font-size: 2rem;
    margin-bottom: 1.875rem !important;
  }

  .onetimePaymentModule .customButton {
    justify-content: center;
    margin-top: 0;
    display: flex;
  }

  .onetimePaymentModule .customButton .action-btn {
    margin-bottom: .625rem;
    display: block;
  }

  .module-heading {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
  }

  .otp-popup .modal-content {
    padding: 1.875rem;
  }

  .otp-popup .modal-header {
    margin-bottom: .75rem;
    padding-left: 0;
    padding-right: 0;
  }

  .otp-popup .modal-header h1 {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  .otp-popup button.close {
    top: 1.25rem;
  }

  .otp-popup .modal-body {
    padding: 0;
  }

  .otp-popup .modal-body img {
    max-width: 100%;
  }

  .otp-popup .tele-call-number {
    font-size: 1.25rem;
  }

  .otp-popup .btn {
    font-size: 1.125rem;
  }

  .otp-popup .btn-close {
    top: -2.188rem;
    right: -1.563rem;
  }

  header .topHeader {
    padding: .813rem 0 .5rem;
  }

  footer {
    padding: 1.25rem 0;
  }

  footer .footer-content {
    display: block;
  }

  footer .footer-content ul {
    text-align: center;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }

  footer .copyright, footer .privacyAct {
    padding-bottom: 0 !important;
  }

  .customFooter_OTP .footer-content ul li {
    text-align: left;
    padding: 0 .938rem .625rem 0;
  }
}

@media screen and (width <= 767px) {
  .otp-account-info {
    width: 100%;
    padding: 1.25rem;
  }

  .otp-popup .modal-header {
    margin-bottom: .75rem;
    padding: 0;
  }

  .otp-popup .btn-close {
    top: -2.188rem;
    right: -.625rem;
  }

  .otp-popup .popupBtn {
    margin-top: 1.563rem;
  }

  .headerLogo.logoCCI img {
    width: 9rem;
    height: 2.5rem;
  }

  .headerLogo.logoFidium img {
    width: 6.25rem;
    height: 2.5rem;
  }

  .otp-popup.modal-dialog {
    margin-top: 1.875rem;
    max-width: 31.25rem !important;
  }

  .onetimePaymentModule .customButton {
    display: block;
  }

  .onetimePaymentModule .customButton .action-btn {
    width: 100%;
  }
}
/*# sourceMappingURL=quickpay.css.map */
